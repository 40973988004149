var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wameed-dashboard-page-content"},[_c('page-header',{attrs:{"title":_vm.$t('financial.title'),"sub-title":_vm.$t('financial.subtitle')}}),_c('div',[_c('filter-header',{attrs:{"content":_vm.content},on:{"applyFilter":_vm.applyFilter,"resetFilter":_vm.resetFilter,"orderApplicants":_vm.orderItems}})],1),_c('section',{staticClass:"\n      wameed-dashboard-page-content_body\n      d-flex\n      flex-column\n      justify-content-between\n      overflow-hidden\n    "},[_c('div',{staticClass:"d-flex flex-wrap flex-1 px-2"},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[_c('div',{staticClass:"w-table d-flex flex-column"},[(_vm.getAllFinancial)?_c('w-tables',{attrs:{"per-page":_vm.filterData.per_page,"items":_vm.getAllFinancial,"fields":_vm.fields,"custimized-items":[
              { name: 'download' },
              { name: 'id' },
              { name: 'amount' },
              { name: 'created_at' } ]},scopedSlots:_vm._u([{key:"id",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[_vm._v("#"+_vm._s(data.item.id))])]}},{key:"amount",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(data.item.amount)+" "+_vm._s(_vm.$t('common.SR'))+" ")])]}},{key:"created_at",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-font-main mx-3"},[_vm._v(" "+_vm._s(_vm.moment(data.item.created_at).format( ' MMMM YYYY DD h:mm A' )))])])]}},{key:"download",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[_c('wameed-btn',{attrs:{"classes":"  text-medium-16 rounded-12 download-btn","title":_vm.$t('financial.download'),"type":"button","append-icon":"download-icon","variant":"background"},on:{"submitAction":function($event){return _vm.downloadReceipt(data.item.id)}}})],1)]}}],null,false,3802006592)}):_vm._e(),(_vm.getAllFinancial && _vm.getAllFinancial.length < 1)?_c('wameed-no-data',{attrs:{"icon":"nodata-icon","title":_vm.$t('packages.no_data'),"sub-title":_vm.$t('packages.no_data_text')}}):_vm._e()],1)]),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[(_vm.getTotalFinancial)?_c('wameed-pagination',{attrs:{"page":_vm.filterData.page,"total-items":_vm.getTotalFinancial.totalItems,"per_page":_vm.filterData.per_page},on:{"changePage":_vm.changePage,"changeCurrentPage":_vm.changeCurrentPage},model:{value:(_vm.filterData.page),callback:function ($$v) {_vm.$set(_vm.filterData, "page", $$v)},expression:"filterData.page"}}):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }